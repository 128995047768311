.accordionItem {
  /* override Bootstrap default accordion-item styling */
  background-color: transparent; 
}

.accordionHeader {
  border-bottom: 1px solid rgba(238,238,238,0.3);
  margin-bottom: 10px;
}

.accordionHeader button {
  color: #fefefe;
  font-size: 13px;
  font-family: 'Open Sans';
  font-weight: 700;
  text-transform: uppercase;

  /* override Bootstrap default accordion-header styling */
  background-color: transparent; 
}

@media screen and (min-width: 1200px) {
  .accordionHeader button {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.accordionHeader button:not(.collapsed) {
  /* override Bootstrap default card-header styling */
  background-color: transparent; 
  color: #fefefe;
}

.accordionHeader button:after {
  /* color accordion toggle caret svg - translates to #fefefe */
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(345deg) brightness(102%) contrast(101%); 

  /* override Bootstrap default accordion toggle caret styling */
  background-size: auto;
  height: 15px;
  width: 15px;
}

.accordionHeader button:focus {
  /* override Bootstrap default button focus styling */
  box-shadow: none; 
}

.accordionBody {
  /* override Bootstrap default accordion-body styling */
  padding: 0;
}

.accordionList {
  text-decoration-style: none;
  list-style-type: none;
  text-align: left; 
  padding-left: 10px;
}

@media screen and (min-width: 1200px) {
  .accordionList {
    padding-left: 30px;
    padding-right: 10px;
  }
}