.icon {
  font-size: 14px;
  top: 15px;
}

.linkTitle {
  display: inline;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 600;
  padding-left: 20px;
}

.linkTitle:hover {
  text-decoration: none;
}

.listItem {
  margin-bottom: 5px;
  margin-right: 20px;
}

.newListItem {
  white-space: nowrap;
}

.listItem a,
.listItem a:hover {
  color: #f4f4f4;
  text-decoration: none;
}

.hrefLink {
  border-radius: 5px;
  color: #ececee;
  display: block;
  padding: 12px;
}

.hrefLink:hover,
.selected {
  background-color: #e91c24;
  color: #fefefe;
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .linkTitle {
    display: none;
  }
  .icon {
    font-size: 30px;
  }
  .listItem {
    text-align: center;
  }
  .hrefLink {
    padding-top: 23px;
  }
}

@media screen and (max-width: 499px) {
  .linkTitle {
    display: none;
  }
  .icon {
    font-size: 20px;
  }
  .listItem {
    text-align: center;
  }
  .hrefLink {
    padding-top: 14px;
  }
}