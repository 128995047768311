.footer {
  color: #fefefe;
  font-size: 10px;
  margin: auto;
  padding-bottom: 8px;
  text-align: center;
}

.footer p {
  margin: auto;
}