.h1Text {
  max-height: 33px;
}

.topBarRow {
  background-color: #5a5f70;
  margin-left: 0;
  margin-right: 0;
}

.topMenu {
  background-color: #fefefe;
  height: 60px;
}

.topBarLogo {
  background-color: #160032;
  padding-top: 5px;
  padding-bottom: 5px;
}